<script setup lang="ts">
import { ref, computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { getFutureAndLiveMatches } from '@/gql/requests'
import Button from '@/components/UI/Button.vue'
import MatchesHeader from '@/components/Match/MatchesHeader.vue'
import CardMatch from '@/components/CardMatch/CardMatch.vue'
import { useUserStore } from '@/stores/user'
import { locale } from '@/i18n'

const userStore = useUserStore()

const activeHeader = ref(true)

const { result } = useQuery(getFutureAndLiveMatches, { locale: locale.value })

const matches = computed(() => {
   const filteredMatches =
      result.value?.matches
         .map((match) => {
            const newOdds =
               match.odds?.matchOutcome?.team1 && match.odds?.matchOutcome?.team2
                  ? [match.odds.matchOutcome.team1, match.odds.matchOutcome.team2]
                  : undefined
            const newOddsLive = undefined
            const team1 = {
               id: match.team1.id,
               name: match.team1.name,
               logo: match.team1.logo,
               logoX1: match.team1.logo_x1,
               logoX2: match.team1.logo_x2
            }
            const team2 = {
               id: match.team2.id,
               name: match.team2.name,
               logo: match.team2.logo,
               logoX1: match.team2.logo_x1,
               logoX2: match.team2.logo_x2
            }

            return { ...match, newOdds, newOddsLive, team1, team2 }
         })
         .filter((match) => match.status === 'UPCOMING')
         .sort((a, b) => {
            if (b.isPromo !== a.isPromo) {
               return Number(b.isPromo) - Number(a.isPromo)
            }

            return (Array.isArray(b.chance) ? 1 : 0) - (Array.isArray(a.chance) ? 1 : 0)
         }) ?? []

   return filteredMatches
})

const matchesShown = ref(6)
const visibleMatches = computed(() => {
   return matches.value.slice(0, matchesShown.value)
})
const showButtonMore = computed(() => matches.value.length > matchesShown.value)
const showMore = () => {
   matchesShown.value += 6
}
</script>

<template>
   <section v-if="matches.length > 0" class="grid gap-y-20px <laptop:px-16px">
      <MatchesHeader v-model="activeHeader" :count="matches.length" :button-filter="false">{{
         $t('dashboard.upcomingMatches.title')
      }}</MatchesHeader>
      <div v-if="activeHeader" class="grid grid-cols-3 items-start <tablethd:grid-cols-2 <tablet:grid-cols-1 gap-20px <fullhd:gap-16px">
         <CardMatch
            v-for="match in visibleMatches"
            :key="match.id"
            :has-subscription="userStore.userSub"
            :match-id="match.id"
            :is-promo="match.isPromo"
            :event-name="match.event.name"
            :event-logo="match.event.logo ?? '#'"
            :match-date="match.date"
            :match-is-live="false"
            :match-ended="false"
            :team1="match.team1"
            :team2="match.team2"
            :chance="match.chance"
            :odds="match.newOdds"
            :odds-live="match.newOddsLive"
            :score="match.score"
            :final-chance="match.finalChance"
            :has-predict="match.hasPredict"
            :winner-id="match.winnerId"
            :level-risk="match.levelRisk ?? undefined"
            :maps="[]"
            :comment="match.comment?.all ?? undefined"
            :author="match.commentAuthor ?? undefined"
         />
      </div>
      <div v-if="activeHeader && showButtonMore" class="flex justify-center">
         <Button class="w-230px h-45px m-semibold" :gradient-start="false" @click="showMore">{{
            $t('dashboard.upcomingMatches.button')
         }}</Button>
      </div>
   </section>
</template>

<style scoped></style>
