<script setup lang="ts">
import { computed } from 'vue'
const props = defineProps<{
   name: string
   mapIsLive: boolean
   matchEnded: boolean
   score?: number[] | null
   teamLeftIsT?: boolean
   chance?: number[] | null
}>()

const chance = computed(() => {
   const chanceExists = !!props.chance
   const data = chanceExists ? props.chance : [0, 0]
   return {
      left: data[0],
      right: data[1],
      exists: chanceExists,
      leftHigh: data[0] > data[1]
   }
})

const score = computed(() => {
   let teamLeft = 'text-gray-800'
   let teamRight = 'text-gray-800'
   let scoreLeft: number | string = '—'
   let scoreRight: number | string = '—'

   if (props.score) {
      scoreLeft = props.score[0]
      scoreRight = props.score[1]

      if (props.mapIsLive && !props.matchEnded) {
         if (props.teamLeftIsT) {
            teamLeft = 'text-yellow-800'
            teamRight = 'text-blue-500'
         } else {
            teamLeft = 'text-blue-500'
            teamRight = 'text-yellow-800'
         }
      } else {
         if (props.score[0] > props.score[1]) {
            teamLeft = 'text-green-500'
            teamRight = 'text-red-700'
         } else {
            teamLeft = 'text-red-700'
            teamRight = 'text-green-500'
         }
      }
   }

   return {
      colorTeam1: teamLeft,
      colorTeam2: teamRight,
      scoreLeft,
      scoreRight
   }
})
</script>

<template>
   <section
      class="h-24px rounded-6px px-8px items-center grid grid-cols-[60px_160px_60px] justify-between m-medium flex-shrink-0"
      :class="mapIsLive && !matchEnded ? 'bg-black-800 shadow-black' : 'opacity-50'"
   >
      <span v-if="matchEnded && (!props.score || !props.chance)" class="text-gray-800"> — </span>
      <div v-else-if="chance.exists" :class="chance.leftHigh ? 'text-green-500' : 'text-red-700'">{{ chance.left }}%</div>
      <i v-else class="i-custom-lock-solid text-gray-50/50"></i>
      <div class="flex items-center justify-end pr-16px s-semibold flex items-center gap-x-8px">
         <span class="uppercase">{{ name }}</span>
         <div class="w-60px grid grid-cols-[1fr_auto_1fr] gap-x-4px rounded-full py-2px bg-black-900/70">
            <span class="justify-self-end" :class="score.colorTeam1">{{ score.scoreLeft }}</span>
            <span class="text-gray-800">:</span>
            <span :class="score.colorTeam2">{{ score.scoreRight }}</span>
         </div>
      </div>
      <span v-if="matchEnded && (!props.score || !props.chance)" class="text-gray-800 justify-self-end"> — </span>
      <div v-else-if="chance.exists" class="justify-self-end" :class="chance.leftHigh ? 'text-red-700' : 'text-green-500'">
         {{ chance.right }}%
      </div>
      <i v-else class="i-custom-lock-solid text-gray-50/50 justify-self-end"></i>
   </section>
</template>

<style scoped></style>
