<script setup lang="ts">
import { computed } from 'vue'
import TeamCardMatch from './TeamCardMatch.vue'

const props = defineProps<{
   hasSubscription: boolean
   team1: {
      id: number
      name: string
      logo?: string | null
      logoX1?: string | null
      logoX2?: string | null
   }
   team2: {
      id: number
      name: string
      logo?: string | null
      logoX1?: string | null
      logoX2?: string | null
   }
   chance?: number[] | null
   leftChanceIsHigher?: boolean
   odds?: number[] | null
   score?: number[] | null
   finalChance: boolean
   hasPredict: boolean
   hasWinner?: boolean
   leftIsWinner?: boolean
   isPromo?: boolean
}>()

const leftOddIsHigher = computed(() => {
   if (!props.odds) {
      return
   }

   return props.odds[0] > props.odds[1]
})
</script>

<template>
   <section class="grid grid-cols-[1fr_auto_1fr] items-center gap-x-8px relative">
      <TeamCardMatch
         :has-subscription="hasSubscription"
         :is-team-left="true"
         :team-name="team1.name"
         :logo="team1.logo"
         :logo-x1="team1.logoX1"
         :logo-x2="team1.logoX2"
         :final-chance="finalChance"
         :has-predict="hasPredict"
         :chance="chance?.[0]"
         :is-higher-chance="leftChanceIsHigher"
         :odd="odds?.[0]"
         :is-higher-odd="leftOddIsHigher"
         :has-winner="hasWinner"
         :is-winner="leftIsWinner"
         :is-promo="isPromo"
      />
      <span class="m-semibold">VS</span>
      <div
         v-if="hasWinner"
         class="w-54px h-42px absolute top-1/2 left-1/2 -translate-1/2 z-3 rounded-10px bg-gray-50/8 border border-gray-50/4 backdrop-blur-25 shadow-black flexc gap-x-4px m-semibold pt-1px"
      >
         <span class="justify-self-end" :class="leftIsWinner ? 'text-green-500' : 'text-red-700'">{{ score?.[0] ?? '—' }}</span>
         <span>:</span>
         <span class="justify-self-end" :class="leftIsWinner ? 'text-red-700' : 'text-green-500'">{{ score?.[1] ?? '—' }}</span>
      </div>
      <TeamCardMatch
         :has-subscription="hasSubscription"
         :is-team-left="false"
         :team-name="team2.name"
         :logo="team2.logo"
         :logo-x1="team2.logoX1"
         :logo-x2="team2.logoX2"
         :final-chance="finalChance"
         :has-predict="hasPredict"
         :chance="chance?.[1]"
         :is-higher-chance="!leftChanceIsHigher"
         :odd="odds?.[1]"
         :is-higher-odd="!leftOddIsHigher"
         :has-winner="hasWinner"
         :is-winner="!leftIsWinner"
         :is-promo="isPromo"
      />
   </section>
</template>

<style scoped></style>
